<template>

  <div id="app">
    <v-app>

    <v-carousel  
      hide-delimiters 
      cycle
      height="200"
      show-arrows="hover"
      >
      <v-carousel-item
        v-for="(item,i) in items_carousel"
        :key="i"
        :src="item.src"
        cover
      ></v-carousel-item>
    </v-carousel>

<v-divider></v-divider>

    <v-content>
      <v-container fluid>
      <ul>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
            <v-card-title>Конечные поля (Galois field)</v-card-title>
            <v-card-text>
              <p>Сгенерируйте порождающие полиномы для конечного поля:</p>
              <p><a href="https://gf.vladlin.ru/gen" target="_blank">https://gf.vladlin.ru/gen</a></p>
              <p>Прочитайте статью на хабре:</p>
              <p><a href="https://habr.com/ru/articles/844300" target="_blank">https://habr.com/ru/articles/844300</a></p>
            </v-card-text>
            <v-img height="300" src="..\assets\img\Evariste_galois.jpg"></v-img>
            <p>Эварист Галуа. Источник: <a href="https://ru.wikipedia.org/wiki/%D0%93%D0%B0%D0%BB%D1%83%D0%B0,_%D0%AD%D0%B2%D0%B0%D1%80%D0%B8%D1%81%D1%82" target="_blank">Википедия</a> </p>
          </v-card>
        </li>


        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
            <v-card-title>The Rick and Morty Reader</v-card-title>
            <v-card-text>
              <p>Android клиент для api <a href="https://rickandmortyapi.com/" target="_blank">https://rickandmortyapi.com/</a></p>
              <p>using Kotlin Dagger Retrofit Moshi Lifecycle Coroutines Epoxy Modo Paging Lottie Glide Eventbus</p>
              <v-chip-group column >
                <v-icon>mdi-github</v-icon>
                <a href="https://github.com/VladlinMoiseenko/RaMReader" target="_blank"> проект доступен на github</a>
                <Vue3Lottie :animationData="MortyJSON" :height="70" :width="70" />
              </v-chip-group>
            </v-card-text>
            <v-img height="300" src="..\assets\img\RaMReader.jpg"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
            <v-card-title>Gotti-android</v-card-title>
            <v-card-text>
              <p>Android-клиент на Kotlin для <a href="https://github.com/VladlinMoiseenko/gotti-server" target="_blank">Gotti сервера</a></p>
              <p>using Dagger Modo Retrofit Moshi Lifecycle Coroutines Epoxy Lottie</p>
              <v-icon>mdi-github</v-icon>
              <a href="https://github.com/VladlinMoiseenko/gotti-android" target="_blank"> проект доступен на github</a>
            </v-card-text>
            <v-img height="300" src="..\assets\img\gotti_android.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
            <v-card-title>Gotti-vue3</v-card-title>
            <v-card-text>
              <p>Веб-клиент на Vue3 для <a href="https://github.com/VladlinMoiseenko/gotti-server" target="_blank">Gotti сервера</a></p>
              <p>using Vuetify Lottie-player Axios</p>
              <v-icon>mdi-github</v-icon>
              <a href="https://github.com/VladlinMoiseenko/gotti-vue3" target="_blank"> проект доступен на github</a>
            </v-card-text>
            <v-img height="300" src="..\assets\img\gotti_vue3.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
            <v-card-title>Gotti-server</v-card-title>
            <v-card-text>
              <p>Простой сервер на Go для хранения <a href="https://lottiefiles.com/" target="_blank">lottie анимаций</a></p>
              <p>using Gofiber Mongodb Swagger Prometheus Grafana Alertmanager Cadvisor</p>
              <v-icon>mdi-github</v-icon>
              <a href="https://github.com/VladlinMoiseenko/gotti-server" target="_blank"> проект доступен на github</a>
              <p>для сервера доступны <a href="https://github.com/VladlinMoiseenko/gotti-vue3" target="_blank">веб-клиент</a> и <a href="https://github.com/VladlinMoiseenko/gotti-android" target="_blank">андроид-клиент</a></p>
            </v-card-text>
            <v-img height="300" src="..\assets\img\gotti_server.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
          <v-card-title>Websocket Chat Android Client</v-card-title>
          <v-card-text>
            <p>Websocket Chat Android клиент для сервера <a href="https://github.com/VladlinMoiseenko/websocket-chat" target="_blank">websocket-chat</a></p>
            <p>using Kotlin Java-WebSocket</p>
            <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/websocket-chat-android-client" target="_blank"> проект доступен на github</a>
          </v-card-text>
          <v-img height="300" src="..\assets\img\websocket_chat_android_client.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
          <v-card-title>Websocket Chat Server</v-card-title>
          <v-card-text>
            <p>Websocket Chat Server <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/websocket-chat" target="_blank">доступен на github</a></p>
            <p>using RUST Actix Actix-web Actix-broker</p>
            <br>
            <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/websocket-chat-android-client" target="_blank"> Websocket Chat Android клиент на github</a>
          </v-card-text>
          <v-img height="300" src="..\assets\img\websocket_chat_server.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
          <v-card-title>GraphQL проект (RUST)</v-card-title>
          <v-card-text>
            <p>GraphQL проект <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/graphql-project" target="_blank">доступен на github</a></p>
            <p>using RUST Ntex Juniper Mysql with r2d2 for database</p>
            <br>
          </v-card-text>
          <v-img height="300" src="..\assets\img\graphiql.png"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
          <v-card-title>Android Clean Architecture</v-card-title>
          <v-card-text>
            <p>Пример Android приложения использующий Clean Architecture by Robert C. Martin (Uncle Bob)</p>
            <p>using Kotlin Retrofit2 RxJava2 Koin Room Glide</p>
            <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/android-kotlin-clean_architecture" target="_blank"> проект доступен на github</a>
          </v-card-text>
          <v-img height="300" src="..\assets\img\android-kotlin-clean_architecture.jpg"></v-img>
          </v-card>
        </li>

        <li>
          <v-card :elevation=card_elevation :width=card_width :height=card_height outlined>
          <v-card-title>iOS Clean Architecture</v-card-title>
          <v-card-text>
            <p>Пример iOS приложения использующий Clean Architecture by Robert C. Martin (Uncle Bob)</p>
            <p>using Swift Alamofire ObjectMapper Kingfisher</p>
            <v-icon>mdi-github</v-icon> <a href="https://github.com/VladlinMoiseenko/ios-swift-clean_architecture" target="_blank"> проект доступен на github</a>
          </v-card-text>
          <v-img height="300" src="..\assets\img\ios-swift-clean_architecture.jpg"></v-img>
          </v-card>
        </li>

      </ul>
      </v-container> 
    </v-content>

    <v-footer class="text-white text-center flex-column">
      <v-card
          elevation="5"
          rounded="5"
          width="100%"
          class=" text-center">
        
        <v-chip-group column>
          <v-card-text>
            <a href="https://www.codewars.com/" target="_blank"><img src="https://www.codewars.com/users/apvladlin/badges/micro" height="25"></a>
          </v-card-text>
          <v-card-text>
            {{ new Date().getFullYear() }} — <a href="https://vladlin.ru/" target="_blank">Vladlin Moiseenko</a>
          </v-card-text>
          <v-card-text>
            <a href="mailto:mvladlin@yandex.ru">mvladlin@yandex.ru</a>
          </v-card-text>
          <v-card-text>
            <a href="http://dw.vladlin.ru/doku.php?id=td" target="_blank">_</a>
          </v-card-text>
        </v-chip-group>

      </v-card>

    </v-footer>

  </v-app>
</div>
</template>

<script>
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import MortyJSON from "../assets/json/morty.json"
  import Carousel_0 from "../assets/img/item_carousel_0.png"
  import Carousel_1 from "../assets/img/item_carousel_1.png"
  import Carousel_2 from "../assets/img/item_carousel_2.png"
  import Carousel_3 from "../assets/img/item_carousel_3.png"
  
  export default {
    components: {
      Vue3Lottie,
    },
    name: 'HomeView',
    data() {
      return {
        MortyJSON,
        card_elevation: 5,
        card_width: 360,
        card_height: 500,
        items_carousel: [
          {src: Carousel_0,},
          {src: Carousel_1,},
          {src: Carousel_2,},
          {src: Carousel_3,},
        ],
      };
    },
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 10px; */
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 10px;
  }

</style>