import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import router from './router'

loadFonts()

createApp(App)
  .use(vuetify)
  .use(Vue3Lottie)
  .use(router)
  .mount('#app')
