import { createWebHistory, createRouter } from "vue-router";
import AboutView from "@/components/AboutView.vue";
import HomeView from "@/components/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;